<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container-fluid">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-6 lg:col-5">
              <div class="">
                <div class="flex align-items-center">
                  <div>
                    <Avatar size="large" class="primary" icon="pi pi-star" />
                  </div>
                  <div class="ml-2">
                    <div class="text-2xl text-primary font-bold">
                      Credential Evaluation
                    </div>
                    <div class="text-sm text-500">
                      List of all certificates that require verification
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 md:col-3 lg:col-2"></div>
            <div class="col-12 md:col-5 lg:col-4">
              <span class="p-input-icon-left w-full">
                <i class="pi pi-search" />
                <InputText
                  outlined
                  dense
                  placeholder="Search"
                  class="w-full"
                  v-model="searchInput"
                />
              </span>
            </div>
          </div>
        </div>
      </section>
    </template>
    <section class="page-section">
      <div class="container-fluid">
        <div class="grid">
          <div class="col comp-grid">
            <div class="flex align-items-center justify-content-around">
              <div
                v-if="searchText"
                class="surface-card p-2 text-500 flex-grow-1 text-center m-1 mb-3 flex-grow-1 text-cente"
              >
                Search
                <Chip
                  class="font-medium px-2 py-1"
                  removable
                  @remove="clearSearch()"
                  >{{ searchText }}</Chip
                >
              </div>
            </div>
            <div class="">
              <div>
                <template v-if="showBreadcrumbs && $route.query.tag">
                  <Breadcrumb
                    :home="{ icon: 'pi pi-home', to: '/' }"
                    :model="pageBreadCrumb"
                  />
                </template>
                <div class="grid">
                  <!-- Master Page Start -->
                  <div class="col-12">
                    <!-- page records template -->
                    <div class="page-records" v-if="!loading">
                      <DataTable
                        :lazy="true"
                        :loading="loading"
                        :value="records"
                        dataKey="applicant_id"
                        @sort="onSort($event)"
                        class=""
                        :showGridlines="false"
                        :rowHover="true"
                        responsiveLayout="stack"
                      >
                        <Column field="applicant_name" header="Applicant Name">
                          <template #body="{ data }">
                              {{ data.applicant_name }}
                          </template>
                        </Column>
                        
                        <Column field="tertiary_certificate_verify_status" header="Approval Status">
                          <template #body="{ data }">
                            <Badge v-if="data.tertiary_certificate_verify_status"
                          size="medium"
                          style="font-size: 0.8rem"
                          class="px-3"
                          :value="data.tertiary_certificate_verify_status "
                          :severity="
                            data.tertiary_certificate_verify_status  == 'Declined'
                              ? 'danger'
                              : data.tertiary_certificate_verify_status  == 'Approved'
                              ? 'success'
                              : 'warning'
                          "
                        ></Badge>
                        <Badge v-else size="medium" style="font-size: 0.8rem" class="px-3" value="Pending" severity="warning"></Badge>
                          </template>
                        </Column>

                        <Column field="tertiary_degree_awarded" header="Degree Awarded">
                          <template #body="{ data }">
                            {{ data.tertiary_degree_awarded }}
                          </template>
                        </Column>

                        <Column field="tertiary_certificate_id" header="Certificate ID">
                          <template #body="{ data }">
                            {{ data.tertiary_certificate_id }}
                          </template>
                        </Column>

                        <Column field="tertiary_certificate" header="Uploaded Certificate">
                          <template #body="{ data }">
                            <a style="text-decoration: none"
                                class="btn text-green-500"
                                target="_blank"
                                :href="
                                  $utils.setFileUrl(
                                    data.tertiary_certificate
                                  )
                                "
                                >Click to view</a
                              >
                          </template>
                        </Column>
                        
                      
                        <Column
                          headerStyle="width: 3em"
                          headerClass="text-center"
                        >
                          <template #body="{ data }">
                            <div class="flex"> 
                               <Menubar
                              class="p-0 p-button p-button-raised p-button-text p-button-sm"
                              ref="actionMenu"
                              :model="getActionMenuModel(data)"
                            />
                            </div>
                          </template>
                        </Column>
                      </DataTable>
                    </div>
                    <!-- page loading indicator -->
                    <template v-if="loading">
                      <div>
                        <DataTable
                          :value="[1, 2, 3, 4, 5]"
                          responsiveLayout="scroll"
                        >
                          <Column style="width: 3rem">
                            <template #body>
                              <Skeleton shape="circle" size="2rem" />
                            </template>
                          </Column>
                          <Column>
                            <template #body>
                              <Skeleton />
                            </template>
                          </Column>
                          <Column>
                            <template #body>
                              <Skeleton borderRadius="16px" />
                            </template>
                          </Column>
                          <Column>
                            <template #body>
                              <Skeleton />
                            </template>
                          </Column>
                          <Column style="width: 3rem">
                            <template #body>
                              <Skeleton size="2rem" />
                            </template>
                          </Column>
                        </DataTable>
                      </div>
                    </template>
                    <!-- end of page loading indicator-->
                    <!-- Empty record -->
                    <template v-if="!loading && !records.length">
                      <div
                        class="p-3 my-3 text-500 text-lg font-medium text-center"
                      >
                        No record found
                      </div>
                    </template>
                    <!-- end of empty record-->
                    <!-- pagination component-->
                    <template v-if="showFooter">
                      <div class="">
                        <div v-show="!loading">
                          <div class="flex justify-content-between">
                            <div
                              class="flex justify-content-center flex-grow-0"
                            >
                              <div
                                class="m-2"
                                v-if="exportButton && records.length"
                              >
                                <Button
                                  @click="
                                    (event) => $refs.exportMenu.toggle(event)
                                  "
                                  label="Export"
                                  title="Export"
                                  class="px-5 py-2 p-button-danger"
                                  icon="pi pi-print"
                                />
                                <Menu
                                  ref="exportMenu"
                                  popup
                                  :model="pageExportFormats"
                                />
                              </div>
                              <div class="m-2">
                              <import-data
                                label="Select a file to import"
                                ref="dataimport"
                                upload-path="applicants/importdata"
                                @importComplete="importComplete"
                              >
                                <Button
                                  @click="$refs.dataimport.openDialog()"
                                  icon="pi pi-folder"
                                  label="Import Data"
                                  class="px-5 py-2 p-button-info"
                                />
                              </import-data>
                            </div>
                          </div>
                            <div
                              v-if="paginate && totalPages > 1"
                              class="flex-grow-1 my-1"
                            >
                              <Paginator
                                @page="
                                  (event) => {
                                    pagination.page = event.page + 1;
                                  }
                                "
                                :rows="pagination.rowsPerPage"
                                :totalRecords="totalRecords"
                              >
                                <template #start>
                                  <span class="text-sm text-gray-500 px-2">
                                    Records
                                    <b
                                      >{{ recordsPosition }} of
                                      {{ totalRecords }}</b
                                    >
                                  </span>
                                </template>
                                <template #end>
                                  <Dropdown v-model="pagination.rowsPerPage"
                                    :options="[5, 10, 15, 20, 30, 40, 50, 100, 200]">
                                  </Dropdown>  
                                </template>
                              </Paginator>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <!-- end of pagination component-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { defineAsyncComponent, ref } from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import { ListPageMixin } from "../../mixins/listpage.js";
import { PageMixin } from "../../mixins/page.js";
export default {
  name: "listApplicantsPage",
  components: {},
  mixins: [PageMixin, ListPageMixin],
  props: {
    primaryKey: {
      type: String,
      default: "applicant_id",
    },
    pageName: {
      type: String,
      default: "applicants",
    },
    routeName: {
      type: String,
      default: "applicantslist",
    },
    apiPath: {
      type: String,
      default: "applicants/index",
    },
    mergeRecords: {
      type: Boolean,
      default: false,
    },
    multiCheckbox: {
      type: Boolean,
      default: false,
    },
    msgBeforeDelete: {
      type: String,
      default: "Are you sure you want to delete this record?",
    },
  },
  data() {
    return {
      pageExportFormats: [
        {
          label: "Excel",
          icon: "pi pi-file-excel text-green-500",
          command: () => {
            this.exportPageRecords("excel", "xlsx");
          },
        },
      ],
    };
  },
  computed: {
    pageTitle: {
      get: function () {
        //set browser page title
        return "Credential Evaluation";
      },
    },
    records: {
      get: function () {
        return this.$store.getters["applicants/records"];
      },
      set: function (value) {
        this.$store.commit("applicants/setRecords", value);
      },
    },
    currentRecord: {
      get: function () {
        return this.$store.getters["applicants/currentRecord"];
      },
      set: function (value) {
        this.$store.commit("applicants/setCurrentRecord", value);
      },
    },
  },
  watch: {
    apiUrl: function () {
      this.load();
    },
  },
  methods: {
    ...mapActions("applicants", ["fetchRecords", "deleteRecord"]),
    getActionMenuModel(data) {
      return [
        {
          label: "Details",
          command: (event) => {
            this.openPageDrawer(
              {
                page: "applicants/view",
                url: `/applicants/view/${data.applicant_id}`,
              },
              {
                width: "50vw",
              }
            );
          },
        },
        {
          label: "Update",
          command: (event) => {
            this.openPageDialog(
              {
                page: "applicants/edit",
                url: `/applicants/edit/${data.applicant_id}`,
              },
              { persistent: true, closeBtn: true }
            );
          },
          icon: "pi pi-pencil",
        },
      ];
    },
    load: function () {
      if (!this.loading) {
        this.loading = true;
        this.currentRecord = null;
        let url = this.apiUrl;
        let payload = {
          url,
          merge: this.mergeRecords,
        };
        this.fetchRecords(payload).then(
          (response) => {
            this.loading = false;
            this.ready = true;
            this.totalRecords = response.total_records;
            this.recordCount = response.record_count;
            this.pagination.rowsNumber = this.totalRecords;
            window.scrollTo(0, 0);
          },
          (response) => {
            this.loading = false;
            this.showPageRequestError(response);
          }
        );
      }
    },
    updateRecord(id, approveStatus) {
      let url = "/applicants/edit/" + id;
      let payload = {
        tertiary_certificate_verify_status: approveStatus,
      };
      this.$api.post(url, payload).then(
        (response) => {
          this.load();
        },
        (response) => {
          this.showPageRequestError(response);
        }
      );
    },
  },
  async mounted() {},
  async created() {},
};
</script>
<style scoped></style>
